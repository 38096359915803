import React, { Component } from "react"
import starIcon from "../../../static/icons/star.svg"
import google from "../../../static/icons/icon-google.svg"

export default class GoogleReviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviews: [
        {
          customer: "Vinno Balakumarasingham",
          img:
            "https://lh3.googleusercontent.com/a/AItbvmk6AOmH5LiHj4zn-HLW20EsS3J2EqJ_83A_F73n=w36-h36-p-c0x00000000-rp-mo-br100",
          content:
            "Fantastic service would highly recommend! The team came to our home and cleaned the car professionally inside and out on our drive.",
          link: "https://goo.gl/maps/6ukKGrEa8A1C3BZC6",
        },
        {
          customer: "Rene Sparkles",
          img:
            "https://lh3.googleusercontent.com/a-/AFdZucrYqWFr83pGRYiEgaATnbz57W6pz8Xmq6GvrHqsNGs=w36-h36-p-c0x00000000-rp-mo-br100",
          content: "They do a stunning thorough job!",
          link: "https://goo.gl/maps/ipTkAnZ5MzJDVMwo9",
        },
        {
          customer: "Karan Sethi",
          img:
            "https://lh3.googleusercontent.com/a-/AFdZucrSY1VehI6gNWv2Com4CK9XOdlaaJVYoJ8Pi8wx9w=w36-h36-p-c0x00000000-rp-mo-br100",
          content:
            "Awesome work in very economical price. & Very neat and clean work in very short time. Much appreciated.",
          link: "https://goo.gl/maps/ecvrzKXqU5cocepG8",
        },
        {
          customer: "Chrissy ihaia",
          img:
            "https://lh3.googleusercontent.com/a/AItbvmnVfWn78ERsmaG1MOmv_KlT3segQuaTcdfQNIno=w36-h36-p-c0x00000000-rp-mo-ba3-br100",
          content:
            "Very thorough, reliable, know , how to treat any car, whatever the value. We get them in every 2 weeks. You want to sell a car, get them to spruce it up, worth the money anytime",
          link: "https://goo.gl/maps/fpvQrRYob7Agy4QTA",
        },
        {
          customer: "Talia Martin",
          img:
            "https://lh3.googleusercontent.com/a/AItbvmn53rqNHBjKW3yqKEfe6T11xl88CGwLESHEz63Z=w36-h36-p-c0x00000000-rp-mo-br100",
          content:
            "Thanks guys, you did a great job with my car. It was so dirty now it's super clean!",
          link: "https://goo.gl/maps/Xp33uEN3yfwAKVZF6",
        },
        {
          customer: "John Taylor",
          img:
            "https://lh3.googleusercontent.com/a/AItbvmkcrWpAL8eioopCRoJK8aqMgRvOEVUdUn1rv27s=w36-h36-p-c0x00000000-rp-mo-ba3-br100",
          content:
            "These guys are awesome, i`ll be calling them next time. My car looked less than impressive, but these guys bought some life back into her. Great job guys. Thanks so much.",
          link: "https://goo.gl/maps/3CxqDL9ePtXdW6z67",
        },
      ],
    }
  }
  render() {
    return (
      <div className="google-reviews">
        {this.state.reviews.map((review, index) => {
          return (
            <div className="google-review" key={index}>
                <div className="google-review__inner">

              <div className="google-review__header">
                <div className="google-review__header__left">
                  <img src={review.img} alt={review.customer} />
                </div>
                <div className="google-review__header__right">
                  <a href={review.link} className="google-review__customer">
                    {review.customer}
                  </a>
                  <span className="google-review__rating">
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                  </span>
                </div>
              </div>
              <p className="google-review__content">{review.content}</p>
              <div className="google-review__footer">
                <div className="google-review__footer__left">
                    <img src={google} alt="google" className="google-review-icon"/>
                </div>
                <div className="google-review__footer__right">
                  Posted on <br /> <a href={review.link}>Google</a>
                </div>
              </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
