import React, { Component } from 'react';
import ToggleSwitch from "../../components/toggle/toggle";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import IconGift from "../../../static/icons/gift-box.svg"

class PriceBlock extends Component {
  constructor() {
    super();
    this.state = {
      gstEnabled: false,
    };
    this.prices = "Prices excluding GST";

      // Wash 1
      this.price_1_1 = "85.00";
      this.price_1_2 = "100.00";
      this.price_1_3 = "120.00";
      // Wash 2
      this.price_2_1 = "160.00";
      this.price_2_2 = "190.00";
      this.price_2_3 = "230.00";
      this.price_2_4 = "250.00";

      // Wash 3
      this.price_3_1 = "310.00";
      this.price_3_2 = "350.00";
      this.price_3_3 = "400.00";
      this.price_3_4 = "420.00";

      // Interior Clean
      this.price_4_1 = "130.00";
      this.price_4_2 = "150.00";
      this.price_4_3 = "170.00";
      this.price_4_4 = "190.00";

    this.class1 = "is-active";
    this.class2 = "is-disabled";

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({
      gstEnabled: !this.state.gstEnabled,
    });
    if (this.state.gstEnabled === true) {
      this.class1 = "is-active";
      this.class2 = "is-disabled";
    } else {
      this.class1 = "is-disabled";
      this.class2 = "is-active";
    }
  }
  render() {
    return (
      <>
        <div className="pricing-block container--light container--centered" id="prices">
          <div className="container">
            <h2 className="heading align--center">Our Prices</h2>
            <div className="align--center">
              <h5 className={`display--inline-block ${this.class1}`}>excl GST &nbsp;</h5>
              <ToggleSwitch
                Name='GST'
                handleClick={this.handleClick}
                gstEnabled={this.state.gstEnabled} />
              <h5 className={`display--inline-block ${this.class2}`}>&nbsp;incl GST </h5>
            </div>
            <h6 className="sub-heading sub-heading--basic align--center">Choose your plan</h6>
            {/* <p className="align--center">All pricing are negotiable based on the frequency of washes and number of fleet provided at a time.</p> */}
            <div className="pricing-block-cards">
              <div className="pricing-block--card">
                <div className="pricing-block-inner">

                  {/* <img src={Icon1} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Eco Special Detail</h5>
                  <h4 className="pricing-block-price"><span className="pre">Small Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_1_1 * 1.15).toFixed(2) : this.price_1_1}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">Medium Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_1_2 * 1.15).toFixed(2) :this.price_1_2}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">SUV / 4x4</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_1_3 * 1.15).toFixed(2) : this.price_1_3}<span className="gst"> plus GST</span></h4>
                  <ul className="price-list-items">
                    <li>Hand Wash</li>
                    <li>Gloss Finish</li>
                    <li>Mag Wheels Cleaned &amp; Dressed</li>
                    <li>Door Frames</li>
                    <li>Vacuum Interior</li>
                    <li>Dashboard Cleaned &amp; Polished</li>
                    <li>Door Jams</li>
                    <li>Windows (inside &amp; out)</li>
                    <li><strong>Tar Removal</strong></li>
                    <li><strong>Hand Wax</strong></li>
                  </ul>
                  <AnchorLink to="https://booking.ecocarwash.co.nz/" title="Book Now" className="button button--centered button--large button--primary">
                    Book Now
                  </AnchorLink>
                </div>
              </div>
              <div className="pricing-block--card">
                <div className="pricing-block-inner">

                  {/* <img src={Icon1} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Eco Super Detail</h5>
                  <h4 className="pricing-block-price"><span className="pre">Small Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_2_1 * 1.15).toFixed(2) : this.price_2_1}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">Medium Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_2_2 * 1.15).toFixed(2) : this.price_2_2}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">SUV / 4x4</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_2_3 * 1.15).toFixed(2) : this.price_2_3}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">7 Seater</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_2_4 * 1.15).toFixed(2) : this.price_2_4}<span className="gst"> plus GST</span></h4>
                  <ul className="price-list-items">
                    <li>Hand Wash</li>
                    <li>Gloss Finish</li>
                    <li>Mag Wheels Cleaned &amp; Dressed</li>
                    <li>Door Frames</li>
                    <li>Vacuum Interior</li>
                    <li>Dashboard Cleaned &amp; Polished</li>
                    <li>Door Jams</li>
                    <li>Windows (inside &amp; out)</li>
                    <li>Tar Removal</li>
                    <li>Hand Wax</li>
                    <li><strong>Shampoo/ Steam Clean Seats &amp; Mats</strong></li>
                    <li><strong>Leather Clean &amp; Conditioner</strong></li>
                  </ul>
                  <AnchorLink to="https://booking.ecocarwash.co.nz/" title="Book Now" className="button button--centered button--large button--primary">
                    Book Now
                  </AnchorLink>
                </div>
              </div>
              <div className="pricing-block--card">
                <div className="pricing-block-inner">
                  {/* <img src={Icon3} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Eco Ultimate Detail</h5>
                  <h4 className="pricing-block-price"><span className="pre">Small Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_3_1 * 1.15).toFixed(2) : this.price_3_1}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">Medium Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_3_2 * 1.15).toFixed(2) : this.price_3_2}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">SUV / 4x4</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_3_3 * 1.15).toFixed(2) : this.price_3_3}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">7 Seater</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_3_4 * 1.15).toFixed(2) : this.price_3_4}<span className="gst"> plus GST</span></h4>
                  <ul className="price-list-items">
                    <li>Hand Wash</li>
                    <li>Gloss Finish</li>
                    <li>Mag Wheels Cleaned &amp; Dressed</li>
                    <li>Door Frames</li>
                    <li>Vacuum Interior</li>
                    <li>Dashboard Cleaned &amp; Polished</li>
                    <li>Door Jams</li>
                    <li>Windows (inside &amp; out)</li>
                    <li>Tar Removal</li>
                    <li>Hand Wax</li>
                    <li><strong>Shampoo / Steam Clean Seats &amp; Mats</strong></li>
                    <li><strong>Leather Clean &amp; Conditioner</strong></li>
                    <li><strong>Full Cut &amp; Polish / Paint Correction</strong></li>
                    <li><strong>Engine Bay Detail</strong></li>
                  </ul>
                  <AnchorLink to="https://booking.ecocarwash.co.nz/" title="Book Now" className="button button--centered button--large button--primary">
                    Book Now
                  </AnchorLink>
                </div>
              </div>
              <div className="pricing-block--card">
                <div className="pricing-block-inner">
                  {/* <img src={Icon4} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Extra Services</h5>
                  <h4 className="pricing-block-price"><span className="pre">Extra Services</span><span className="currency"></span>POA<span className="decimal"></span></h4>
                  <ul className="price-list-items">
                    <li>Clay Whole Car</li>
                    <li>Machine Cut &amp; Polish</li>
                    <li>Engine Bay Clean &amp; Dressed</li>
                    <li>Pet Hair Removal</li>
                    <li>Bad Odour Removal</li>
                    <li>Chrome Polish</li>
                    <li>Water marks removal</li>
                    <li>Stone chip removal</li>
                    <li>Signage removal</li>
                    <li>Color restoration</li>
                    <li>Home sofas/couches steam clean</li>
                    <li>Leather clean &amp; condition</li>
                  </ul>
                </div>
              </div>
              <div className="pricing-block--card pricing-block--card--third pricing-block--card--new">
                <div className="pricing-block-inner">
                  {/* <img src={Icon4} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Undercarriage Wash</h5>
                  <h4 className="pricing-block-price"><span className="pre">Undercarriage Wash</span>POA</h4>
                  <br></br>
                  <p className="align--center text--uppercase text--bold">Revitalize your vehicle's undercarriage with our innovative undercarriage cleaning tool.</p>
                  <ul className="price-list-items">
                    {/* <li>Outside wash &amp; wax</li>
                    <li>Mag wheels clean and dressed</li>
                    <li>Windows clean outside</li> */}
                    <AnchorLink to="#contact" title="Get in touch" className="button button--centered button--large button--primary">
                    Get a quote
                  </AnchorLink>
                  </ul>
                </div>
              </div>
              <div className="pricing-block--card pricing-block--card--third">
                <div className="pricing-block-inner">
                  {/* <img src={Icon4} alt="" loading="lazy" /> */}
                  <h5 className="align--center">Interior Steam Clean / Shampoo</h5>
                  <p className="align--center text--uppercase text--bold">Pet hair removal / extra dirty vehicles will be charged extra.</p>
                  <h4 className="pricing-block-price"><span className="pre">Small Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_4_1 * 1.15).toFixed(2) : this.price_4_1}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">Medium Car</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_4_2 * 1.15).toFixed(2) : this.price_4_2}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">SUV / 4x4</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_4_3 * 1.15).toFixed(2) : this.price_4_3}<span className="gst"> plus GST</span></h4>
                  <h4 className="pricing-block-price"><span className="pre">7 Seater</span><span className="currency">$</span>{this.state.gstEnabled ? (this.price_4_4 * 1.15).toFixed(2) : this.price_4_4}<span className="gst"> plus GST</span></h4>
                  <ul className="price-list-items">
                    {/* <li>Outside wash &amp; wax</li>
                    <li>Mag wheels clean and dressed</li>
                    <li>Windows clean outside</li> */}
                  <AnchorLink to="https://booking.ecocarwash.co.nz/" title="Book Now" className="button button--centered button--large button--primary">
                    Book Now
                  </AnchorLink>
                  </ul>
                </div>
              </div>
              <div className="pricing-block--card pricing-block--card--third">
                <div className="pricing-block-inner">
                  <div className="pricing-block-imageContainer">
                    <img src={IconGift} alt="Gift Certificate" loading="lazy" />
                  </div>
                  <h5 className="align--center">Looking to gift one of our services?</h5>
                  <p className="align--center text--uppercase text--bold">We have got you covered.</p>
                  <p>Visit our dedicted gift certificate site to help you provide your loved ones with one of our exclusive car grooming services.</p>
                  <AnchorLink to="https://paintprotection.ecocarwash.co.nz/gift-certificate" title="Buy a Gift Certificate" className="button button--centered button--large button--primary">
                    Buy a Gift Certificate
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
          <p className="align--center">*{this.state.gstEnabled ? 'Prices including GST' : 'Prices excluding GST'}</p>
          <p className="align--center">Pet hair removal / extra dirty vehicles will be charges extra and owner will be notified immeditely.</p>
        </div>
      </>
    )
  }
}

export default PriceBlock;