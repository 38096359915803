import React from "react"

export default function Quad(props) {
  return <> 
  <div className={`quad-container quad-container--${props.align} quad-container--${props.type}`} id={props.id}>
    <div className="container">
        {props.children}
    </div>
  </div>
  </>
}