import React from "react"

export default function Double(props) {
  return <> 
  <div className={`double-container double-container--${props.align} double-container--${props.type}`} id={props.id}>
    <div className="container">
        {props.children}
    </div>
  </div>
  </>
}