import React, { Component } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Image1 from "../../../static/img/logo-wurth.png"
import Image2 from "../../../static/img/logo-plant-food.png"
import Image3 from "../../../static/img/logo-libbet.png"
import Image4 from "../../../static/img/logo-pbtech.png"
import Image5 from "../../../static/img/logo-ghd.png"
import Image6 from "../../../static/img/logo-ucg.png"
import Image7 from "../../../static/img/logo-traffica.jpeg"
import Image8 from "../../../static/img/logo-cll.png"

export default class ClientsCarousel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      mobileFirst: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <Slider className="partners" {...settings}>
        <div className="slide">
          <img src={Image1} alt="Wurth" />
        </div>

        <div className="slide">
          <img src={Image2} alt="Plant and Food Research" />
        </div>

        <div className="slide">
          <img src={Image3} alt="Libbet" />
        </div>

        <div className="slide">
          <img src={Image4} alt="PB Tech" />
        </div>

        <div className="slide">
          <img src={Image5} alt="GHD"/>
        </div>

        <div className="slide">
          <img src={Image6} alt="UCG" />
        </div>

        <div className="slide">
          <img src={Image7} alt="Traffica" />
        </div>

        <div className="slide">
          <img src={Image8} alt="CLL Service &amp; Solutions" />
        </div>
      </Slider>
    )
  }
}
