import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Hero(props) {
  return <> 
  <div className="hero-container">
    <div className="container">
      {/* <h5 className="hero-container-pretitle">{props.pretitle}</h5> */}
      <h1 className="hero-container-title">Car Wash <span>Solutions</span></h1>
      <p className="hero-container-description">{props.description}</p>
      <AnchorLink className="button button--large button--primary" to="/#contact" title="Request a quote">
        Get a quick quote
      </AnchorLink>
      <AnchorLink className="button button--large button--secondary" to="http://paintprotection.ecocarwash.co.nz" title="Try our new paint protection">
        Try our new paint protection
      </AnchorLink>
    </div>
  </div>
  </>
}